@tailwind base;
@tailwind components;
@tailwind utilities;
#alert-root {
  z-index: 1061; /* Satu index di atas sweetalert2. */
  @apply fixed bottom-10 right-10 w-96 pointer-events-none;
}


@layer base {
  strong {
    @apply font-bold;
  }
}

@layer utilities {
  ol {
    @apply list-decimal list-inside;
  }

  ul {
    @apply list-disc list-inside;
  }

  .bg-primary {
    background-color: #00a65a;
  }

  .bg-danger {
    background-color: #dd4b39;
  }

  .bg-warning {
    @apply bg-yellow-500;
  }

  .bg-info {
    background-color: #00c0ef;
  }

  .text-primary {
    color: #00a65a;
  }

  .text-danger {
    color: #dd4b39;
  }

  .text-info {
    color: #00c0ef;
  }

  .link {
    @apply text-blue-500 font-normal;

    &:hover {
      /* display: none; */
      @apply text-blue-600;
    }
  }

  .font-readable {
    font-family: Verdana, sans-serif;
    @apply leading-normal
  }
}

@font-face {
  font-family: 'HEY JUNE';
  src: local('HEY JUNE'), url('./assets/fonts/HEY JUNE.otf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Traditional Arabic';
  src: local('Traditional Arabic'), url('./assets/fonts/tradbdo.ttf') format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: Poppins;
  background-color: #fff;
}

.arabic {
  font-family: 'Traditional Arabic';
  font-size: 2rem !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(107, 114, 128, 1);
}
